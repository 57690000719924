<template>
  <el-dialog
    title="绑定主体"
    :visible="visible"
    width="500px"
    @close="handleClose"
  >
    <el-form
      ref="bindEntityForm"
      :rules="formRules"
      :model="formModel"
      label-position="top"
    >
      <el-form-item label="绑定主体" :prop="bindEntity">
        <el-select
          class="w-100"
          v-model="formModel.bindEntity"
          filterable
          remote
          placeholder="请输入绑定主体名称/ID/主域名搜索"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="confirmBindEntity">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import cusForm from "@/views/components/cusForm";
export default {
  components: {
    cusForm
  },
  props: {
    visible: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      formModel: {
        bindEntity: ""
      },
      formRules: {
        bindEntity: {
          required: true,
          message: "请输入绑定主体名称/ID/主域名搜索",
          trigger: ["change"]
        }
      },
      loading: false,
      options: []
    };
  },
  computed: {},
  methods: {
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          //  调用接口重新赋值this.options
        }, 200);
      } else {
        this.options = [];
      }
    },
    confirmBindEntity() {
      this.$refs.bindEntityForm.validate(valid => {
        if (valid) {
          this.$emit("comfirm-bind-entity", this.formModel);
        } else {
          return false;
        }
      });
    },
    handleClose() {
      this.$emit("close-dialog");
      this.$refs.bindEntityForm.resetFields();
    }
  }
};
</script>

<style lang="scss">
</style>