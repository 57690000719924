<template>
  <div class="ad-wrap h100 dp-flex flex-d-c">
    <div class="header dp-flex justify-btw">
      <ul class="dp-flex">
        <li v-for="(item, index) in showItems" :key="index">
          <p class="title text-a-c f-12">{{ item.title }}</p>
          <p class="count text-a-c">
            <span v-if="index != 0">$</span>{{
              index != 0
              ? resData[item.prop] / 100
              : resData[item.prop]
                ? resData[item.prop]
                : 0
            }}<span v-if="index == 0">个</span>
          </p>
        </li>
      </ul>
      <el-input class="align-self-start" placeholder="输入广告账号名称/ID搜索" v-model="keyword">
        <el-button @click="handleSearch" slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div>
    <div v-if="multipleSelection.length" class="batch-oparate-wrap dp-flex align-item-c justify-btw">
      <div class="dp-flex align-item-c justify-btw">
        <el-button size="mini" type="primary" round>已选{{ multipleSelection.length }}项</el-button>
        <span class="f-14 color-theme" style="margin: 0 10px 0 5px">批量操作</span>

        <div @mouseenter="handleMouseEvent({ index, mark: 'enter' })"
          @mouseleave="handleMouseEvent({ index, mark: 'leave' })" v-for="(item, index) in batchIconData" :key="index"
          class="dp-flex justify-c align-item-c batch-icon" style="width: 60px; margin-left: 5px">
          <el-tooltip v-if="item.iconClassName.indexOf('iconfont') != -1" content="清理账户下的主页和像素的缓存">
            <div>
              <i :class="item.iconClassName" v-html="item.iconClassName.indexOf('iconfont') != -1 ? item.code : ''
                "></i>
              <el-button @click="handleOperateIcon({ text: item.text })" slot="reference" type="primary" plain size="mini"
                :class="item.textClassName">{{ item.text }}</el-button>
            </div>
          </el-tooltip>
          <div v-else>
            <i :class="item.iconClassName" v-html="item.iconClassName.indexOf('iconfont') != -1 ? item.code : ''
              "></i>
            <el-button @click="handleOperateIcon({ text: item.text })" slot="reference" type="primary" plain size="mini"
              :class="item.textClassName">{{ item.text }}</el-button>
          </div>
        </div>
      </div>
      <i @click="$refs.cusTableRef.$refs.tableRef.clearSelection()" class="el-icon-close"></i>
    </div>
    <cus-table ref="cusTableRef" :cellMouseEnter="cellMouseEnter" :cellMouseLeave="cellMouseLeave"
      :tableParams="tableParams" :tableData="tableData" :tableHeightData="tableHeightData" :colData="colData"
      :loading="loading" :headerCellClassName="headerCellClassName" @select-change="handleSelectionChange">
      <template v-slot:cusheader="{ scope, col }">
        <div v-if="col.prop == 'timezoneName' || col.prop == 'amountSpent'">
          <el-tooltip effect="dark" :content="col.headerTooltip" placement="top">
            <span>{{ col.label }}<i class="el-icon-question"></i></span>
          </el-tooltip>
        </div>
        <!-- <div v-else-if="col.prop == 'amountSpent'">
          <el-tooltip
            effect="dark"
            :content="
              scope.column.order == 'ascending'
                ? '点击降序'
                : scope.column.order == 'descending'
                ? '取消排序'
                : '点击升序'
            "
            placement="top"
          >
            <span
              >{{ scope.column.label }}<i class="el-icon-question"></i
            ></span>
          </el-tooltip>
        </div> -->
      </template>
      <template v-slot:colslot="{ scope, prop }">
        <div v-if="prop == 'balance'">
          ${{
            !scope.row.spendCap
            ? "未设置上限"
            : (scope.row.spendCap - scope.row.amountSpent) / 100
          }}
        </div>
        <div v-else-if="prop == 'amountSpent'">
          ${{ scope.row[prop] / 100 }}
        </div>
        <div v-else-if="prop == 'pixelsCount'">
          <el-tooltip effect="light" content="点击查看绑定到该广告帐号的Pixel像素">
            <span @click="handleToPixelPage(scope)" class="cursor-pointer color-theme">
              {{ scope.row[prop] ? scope.row[prop] : "--" }}
            </span>
          </el-tooltip>
        </div>
        <!-- 名称 -->
        <div v-else-if="prop == 'name'">
          <div class="dp-flex justify-btw pr cus-name">
            <p>
              <el-tooltip :content="scope.row.accountStatus == 2 ? '禁用' : '激活'">
                <i class="flag-i" :class="scope.row.accountStatus == 2
                    ? 'el-icon-warning color-danger'
                    : 'el-icon-success color-success'
                  "></i>
              </el-tooltip>
              <el-tooltip :visible-arrow="false" effect="light" content="切换到该帐号并进入广告管理">
                <span @click="handleChangeAccount(scope.row)" class="ad-name cursor-pointer">{{ scope.row.displayName || scope.row.name }}</span>
              </el-tooltip>
              <!-- <el-tooltip
              :visible-arrow="false"
              effect="light"
              content="切换到该帐号并进入广告管理"
            > -->
              <!--  @click="handleChangeAccount(scope.row)" -->
              <span class="ad-no cursor-pointer">{{ scope.row.id }}</span>
              <!-- </el-tooltip> -->
            </p>
            <!-- 操作icon -->
            <div v-show="scope.row.showIcon" class="pa row-operate-icon dp-flex">
              <div @mouseenter.stop="
                handleMouseEvent({ pindex: scope.$index, index, mark: 'enter' })
                " @mouseleave.stop="
    handleMouseEvent({ pindex: scope.$index, index, mark: 'leave' })
    " v-for="(item, index) in scope.row.iconData" :key="index" class="dp-flex justify-c align-item-c">
                <el-tooltip v-if="item.iconClassName.indexOf('iconfont') != -1" content="清理账户下的主页和像素的缓存">
                  <div>
                    <i :class="item.iconClassName" v-html="item.iconClassName.indexOf('iconfont') != -1
                        ? item.code
                        : ''
                      "></i>
                    <el-button @click="
                      handleOperateIcon({ row: scope.row, text: item.text })
                      " slot="reference" type="primary" plain size="mini" :class="item.textClassName">{{ item.text
  }}</el-button>
                  </div>
                </el-tooltip>
                <div v-else>
                  <i :class="item.iconClassName" v-html="item.iconClassName.indexOf('iconfont') != -1
                      ? item.code
                      : ''
                    "></i>
                  <el-button @click="
                    handleOperateIcon({ row: scope.row, text: item.text })
                    " slot="reference" type="primary" plain size="mini" :class="item.textClassName">{{ item.text
  }}</el-button>
                </div>
              </div>
            </div>
          </div>
          <p class="displayName" v-if="scope.row.displayName">{{ scope.row.name }}</p>
        </div>
      </template>
    </cus-table>
    <el-pagination class="text-a-r page" style="margin-top: 16px" background @current-change="handleCurrentChange"
      :hide-on-single-page="true" layout="prev, pager, next" :total="total">
    </el-pagination>
    <Recharge :dialogVisible.sync="showRecharge" @close="showRecharge = false" :account_id="rechargeAccountId"></Recharge>
    <set-display-name :setNameShow.sync="setNameShow" :selectedRow="selectedRow" @setName="setName"></set-display-name>
  </div>
</template>

<script>
import cusTable from "@/views/components/cusTable";
import { getAdAccouts, clearCache } from "@/api/adAssets";
import { checkAccountStatus } from "@/api/common/account";
import Recharge from '@/components/google/recharge.vue'
import setDisplayName from "./setDisplayName.vue";
import {unsetContent} from '@/api/adManagement'
export default {
  components: {
    cusTable,
    Recharge,
    setDisplayName
  },
  props: ["isSync"],
  data() {
    return {
      page: 1,
      limit: 10,
      total: 10,
      tableParams: {
        border: true,
        selection: true,
        isTableHeight: true
      },
      tableHeightData: [
        { selector: ".navbar", cssProperty: "height" },
        { selector: ".app-main", cssProperty: "paddingTop" },
        { selector: ".app-main", cssProperty: "paddingBottom" },
        { selector: ".cus-tab-wrap>.el-tabs__header", cssProperty: "height" },
        {
          selector: ".cus-tab-wrap>.el-tabs__content",
          cssProperty: "paddingTop"
        },
        {
          selector: ".cus-tab-wrap>.el-tabs__content",
          cssProperty: "paddingBottom"
        },
        { selector: ".ad-wrap>.header", cssProperty: "height" },
        { selector: ".ad-wrap>.header", cssProperty: "marginBottom" },
        { selector: ".ad-wrap>.batch-oparate-wrap", cssProperty: "height" },
        {
          selector: ".ad-wrap>.batch-oparate-wrap",
          cssProperty: "marginBottom"
        }
      ],
      // 表格
      tableData: [],
      colData: [
        { label: "名称", prop: "name", minWidth: "400px" },
        {
          label: "时区",
          prop: "timezoneName",
          headerTooltip: "开启时选择的时区是广告帐户的默认时区，不可更改"
        },
        { label: "剩余额度", prop: "balance" },
        {
          label: "花费",
          prop: "amountSpent",
          headerTooltip: "最近一个月的花费",
          sortable: true
        },
        { label: "BM", prop: "businessName", showOverTooltip: true },
        { label: "Pixel 像素", prop: "pixelsCount" }
      ],
      multipleSelection: [],
      // 接口返回数据
      resData: { remainTotal: "", spendTotal: "", total: "" },
      loading: false,
      keyword: "",
      // 表格上方数据总览
      showItems: [
        { title: "Facebook广告账户", prop: "total" },
        { title: "剩余额度", prop: "remainTotal" },
        { title: "花费", prop: "spendTotal" }
      ],
      selectedRow: {},
      // 批量操作-图标
      batchIconData: [
        {
          iconClassName: ["el-icon-document"],
          textClassName: ["dp-none"],
          text: "命名规则"
        },
        {
          iconClassName: ["el-icon-position"],
          textClassName: ["dp-none"],
          text: "转化追踪"
        },
        {
          iconClassName: ["el-icon-s-tools"],
          textClassName: ["dp-none"],
          text: "广告结构"
        },
        {
          iconClassName: ["iconfont"],
          textClassName: ["dp-none"],
          code: "&#xe7ea;",
          text: "清缓存"
        }
      ],
      showRecharge: false,// 充值显示
      rechargeAccountId: null,// 充值账户id
      setNameShow: false,//设置别名
    };
  },
  methods: {
    clearCache(params) {
      this.$showLoading();
      clearCache(params).then(res => {
        this.$hideLoading();
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "清理账户下的主页和像素缓存成功！"
          });
        }
      });
    },
    // 检查广告帐号状态-启用/禁用
    checkAccountStatus(item) {
      this.$showLoading();
      return checkAccountStatus({ id: item.id }).then(res => {
        this.$hideLoading();
        if (res.code == 0) {
          let pass = res.data.check_pass;
          // 禁用时弹出切换确认框
          if (!pass) {
            this.$confirm(
              `<p style="margin-top: 10px;">即将要切换到的广告账号<strong>${item.name}</strong>状态是：<strong>禁用</strong> ，只有启用中的账号才能创建/编辑广告。</p><p>确定要切换到该帐号并进入广告管理吗？</p>`,
              "切换帐号确认",
              {
                distinguishCancelAndClose: true,
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                customClass: "change-status-msg",
                type: "warning",
                dangerouslyUseHTMLString: true
              }
            )
              .then(() => {
                this.$router.push({
                  name: "adManagement",
                  query: { obj: item }
                });
              })
              .catch(action => {
                if (action == "cancel") {
                  this.$message({
                    type: "info",
                    message: `已取消切换到帐号${item.name}!`
                  });
                }
                this.getAdAccouts();
              });
          } else {
            this.$router.push({ name: "adManagement", query: { obj: item } });
          }
        }
      });
    },
    headerCellClassName({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 0 && columnIndex == 4) {
        return "cus-spend";
      }
    },
    // 切换到该帐号并进入广告管理
    handleChangeAccount(row) {
      this.checkAccountStatus(row);
    },
    // 当前页改变
    handleCurrentChange(val) {
      this.page = val;
      this.getAdAccouts();
    },
    // 获取广告账户列表
    getAdAccouts() {
      let params = {
        keyword: this.keyword,
        limit: this.limit,
        page: this.page,
        id: this.$store.state.num.selectedNum,
        isNeedPixel: true
      };
      return getAdAccouts(params).then(res => {
        if (res.code == 200) {
          if (res.data.list) {
            let arr = JSON.parse(JSON.stringify(res.data.list));
            // 行操作
            let iconData = [
              {
                iconClassName: ["el-icon-edit"],
                textClassName: ["dp-none"],
                text: "设置别名"
              },
              {
                iconClassName: ["el-icon-setting"],
                textClassName: ["dp-none"],
                text: "偏好设置"
              },
              {
                iconClassName: ["el-icon-s-open"],
                textClassName: ["dp-none"],
                text: "清空偏好"
              },
              {
                iconClassName: ["el-icon-s-promotion"],
                textClassName: ["dp-none"],
                text: "发广告"
              },
              {
                iconClassName: ["iconfont"],
                textClassName: ["dp-none"],
                code: "&#xe7ea;",
                text: "清缓存"
              }, {
                iconClassName: ['el-icon-s-cooperation'],
                textClassName: ["dp-none"],
                text: '充值'
              }
            ];
            arr.forEach(item => {
              this.$set(item, "iconData", iconData);
            });
            // amountSpent-取整便于el-table的sortable
            arr.forEach(item => {
              item.amountSpent = item.amountSpent / 1;
            });
            // console.log("arring", arr);
            this.tableData = arr;
            // 表上方条目
            Object.keys(this.resData).forEach(p => {
              this.resData[p] = res.data[p];
            });
            this.total = res.data.total;
            if (this.total > this.limit) {
              this.$bus.$emit("trigger-height-change-page", 16);
            }
          }
        }
      });
    },
    // 点击像素
    handleToPixelPage(scope) {
      this.$emit("topixelpage", scope);
    },
    // 搜索
    handleSearch() {
      this.page = 1;
      this.getAdAccouts();
    },
    // 鼠标移入row
    cellMouseEnter(row, column, cell, event) {
      this.$set(row, "showIcon", true);
    },
    // 鼠标移出row
    cellMouseLeave(row, column, cell, event) {
      this.$set(row, "showIcon", false);
    },
    // 鼠标移入移出事件
    handleMouseEvent({ pindex, index, mark }) {
      let obj = {};
      if (pindex != undefined) {
        obj = this.tableData[pindex].iconData[index];
      } else {
        obj = this.batchIconData[index];
      }
      switch (mark) {
        case "enter":
          obj.iconClassName.push("dp-none");
          obj.textClassName = [];
          break;
        case "leave":
          let i = "";
          i = obj.iconClassName.findIndex(item => item == "dp-none");
          obj.iconClassName.splice(i, 1);
          obj.textClassName = ["dp-none"];
          break;
        default:
          break;
      }
    },
    // 行操作icon
    handleOperateIcon({ row, text }) {
      if (row != undefined) {
        let name = row.name;
        this.selectedRow = row;
      }
      let accounts = this.multipleSelection.map(item => item.id);
      switch (text) {
        // 表格行
        case "设置别名":
          this.setNameShow = true;
          break;
        case "偏好设置":
          this.$router.push({
            name: "preferenceSet",
            query: { id: row.id, name: row.name, mark: "all" }
          });
          break;
        case "清空偏好":
          this.clearLikeSet(row.id)
          break;
        case "发广告":
          this.$router.push({
            name: "CreateAd",
            query: { account_id: row.account_id }
          });
          break;
        case "清缓存":
          let ids = [];
          if (row != undefined) {
            // console.log("行内-清缓存");
            ids = [row.id];
          } else {
            // console.log("批量-清缓存");
            ids = [...accounts];
          }
          this.clearCache(ids);
          break;
        // 批量操作
        case "命名规则":
          this.$router.push({
            name: "preferenceSet",
            query: { accounts, mark: "name" }
          });
          break;
        case "转化追踪":
          this.$router.push({
            name: "preferenceSet",
            query: { accounts, mark: "trace" }
          });
          break;
        case "广告结构":
          this.$router.push({
            name: "preferenceSet",
            query: { accounts, mark: "ad" }
          });
          break;
        case '充值':
          this.showRecharge = true
          this.rechargeAccountId = row.id
        default:
          break;
      }
    },
    // setName
    setName(params){
      let index = this.tableData.findIndex(v=>v.id == params.id);
      this.$set(this.tableData,index,{...this.tableData[index],displayName:params.displayName})
    },
    // 清空偏好
    clearLikeSet(id){
      this.$confirm('确定删除该账户的偏好设置吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$showLoading()
          unsetContent({actId:id}).then((res) => {
            this.$hideLoading()
            if(res.code == 0){
              this.$message({
                type:'success',
                message:'操作成功'
              })
            }
          })
        }).catch(() => {    
        });
    },
    // 表格多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$bus.$emit("trigger-height-change");
    }
  },
  async mounted() {
    // 获取广告账户列表
    await this.getAdAccouts();
  },
  watch: {
    // 当同步成功后重新调用列表接口
    isSync(val) {
      if (val) {
        this.getAdAccouts();
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";

.ad-wrap {
  color: $theme-color;

  .header {
    margin-bottom: 8px;

    .el-input {
      width: auto;
    }

    li {
      min-width: 150px;
      border-right: 1px solid #e5e6e9;
      padding: 0 15px;

      &:last-child {
        border-right: none;
      }
    }

    .count {
      font-weight: 700;
      margin-top: 10px;
    }
  }

  .batch-oparate-wrap {
    padding: 0 16px;
    margin-bottom: 8px;
    background: rgb(232, 241, 255);
    height: 32px;

    .el-button--mini,
    .el-button--mini.is-round {
      padding: 2px 10px;
    }
  }

  .cus-name {
    .flag-i {
      margin: {
        left: 7px;
        right: 10px;
      }
    }

    .ad-name {
      font-weight: bold;
      // &:hover {
      color: $theme;
      // }
    }

    .ad-no {
      font-size: 12px;
      color: rgb(185, 187, 190);
      margin-left: 12px;
    }
  }

  .row-operate-icon {
    top: 0;
    right: 56px;
    height: 100%;
    background: #f8f8fa;

    &>div {
      padding: 0 5px;
      min-width: 46px;
      max-width: 120px;
    }

    i {
      font-size: 18px;
    }

    .el-icon-more {
      border: 1px solid $theme;
      border-radius: 50%;
    }

    .el-button--mini {
      padding: 5px 10px;
    }
  }

  .el-table__body-wrapper {
    height: auto !important;
  }

  .cus-spend {
    .cell {
      @extend .dp-flex;
      @extend .align-item-c;
    }
  }
}
.displayName{
  margin-left: 30px;
  color: #999;
}
.change-status-msg {
  .el-message-box__status {
    top: unset;
    transform: translateY(0);
  }
}
</style>