<template>
  <el-dialog
    :title="title"
    :visible="visible"
    width="500px"
    @close="handleClose"
  >
    <cus-form
      ref="addEditEntityForm"
      :formDatas="{ labelPositon: 'top' }"
      :formItemDatas="formItemDatas"
      :formModelFn="formModelFn"
      :formRulesFn="formRulesFn"
      :initModel="initModel"
    ></cus-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="confirmEntity">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import cusForm from "@/views/components/cusForm";
export default {
  components: {
    cusForm
  },
  props: {
    title: {
      type: String,
      default: () => "标题"
    },
    visible: {
      type: Boolean,
      default: () => false
    },
    initModel: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // 表单
      formItemDatas: [
        {
          label: "公司名称",
          type: "input",
          prop: "name",
          placeholder: "请输入公司名称"
        },
        {
          label: "主域名",
          type: "input",
          prop: "urls",
          placeholder: "请输入主域名"
        },
        {
          label: "团队",
          type: "select",
          prop: "areaId",
          placeholder: "请选择团队",
          options: []
        },
        {
          label: "是否禁用",
          type: "switch",
          prop: "status"
        }
      ],
      requiredProps: []
    };
  },
  computed: {
    formModelFn() {
      let obj = {};
      this.formItemDatas.forEach(item => {
        obj[item.prop] = "";
      });
      return obj;
    },
    formRulesFn() {
      let obj = {};
      let requiredItems = this.formItemDatas.filter(item => {
        return this.requiredProps.some(p => p == item.prop);
      });
      requiredItems.forEach(item => {
        obj[item.prop] = {
          required: true,
          message: item.placeholder,
          trigger: ["change"]
        };
      });
      // console.log("formRulesFn", obj);
      return obj;
    }
  },
  methods: {
    confirmEntity() {
      // console.log("this.$refs.addEditEntityForm", this.$refs.addEditEntityForm);
      // console.log(
      //   "this.$refs.addEditEntityForm.formModel",
      //   this.$refs.addEditEntityForm.formModel
      // );
      this.$refs.addEditEntityForm.$refs.formRef.validate(valid => {
        if (valid) {
          this.$emit("comfirm-entity", this.$refs.addEditEntityForm.formModel);
        } else {
          return false;
        }
      });
    },
    handleClose() {
      this.$emit("close-dialog");
      this.$refs.addEditEntityForm.$refs.formRef.resetFields();
    }
  }
};
</script>

<style lang="scss">
</style>